import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { useDispatch, useSelector } from 'react-redux';
import { BookingSlice } from "../../state/booking";

const steps = ['Select Dates', 'Choose Room', 'Personal Detail'];

export default function CheckoutSteps() {

    const currentStep = useSelector(state => state.booking.activeStep);
    const dispatch = useDispatch();

    console.log("step is " + currentStep);

    const totalSteps = () => {
        return steps.length;
    };

    const isLastStep = () => {
        return currentStep === totalSteps() - 1;
    };



    const handleStep = (step) => {
        console.log("new step asked is " + step);
        if (step < currentStep) {
            dispatch(BookingSlice.actions.goToPreviousStep(step));
        }

    };



    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={currentStep}>
                {steps.map((label, index) => (
                    <Step key={label} >
                        <StepButton color="$006677" onClick={() => handleStep(index)}>
                            <h5>{label}</h5>
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}