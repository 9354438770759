import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    checkin: null,
    checkout: null,
    guestAdult: 1,
    guestChild: 0,
    activeStep: 0,
    rooms: null,
};

export const BookingSlice = createSlice({
    name: "booking",
    initialState,
    reducers:
    {
        updateCheckin(state, action) {
            state.checkin = action.payload;
        },
        updateCheckout(state, action) {
            state.checkout = action.payload;
        },
        setAdultGuest(state, action) {
            if (action.payload > 0 && action.payload <= 6) {
                state.guestAdult = action.payload;
            }

        },
        setChildGuest(state, action) {
            if (action.payload >= 0 && action.payload <= 6) {
                state.guestChild = action.payload;
            }

        },

        increaseAdultGuests(state) {
            if (state.guestAdult < 6) {
                state.guestAdult++;
            }

        },
        decreaseAdultGuests(state) {
            if (state.guestAdult > 0) {
                state.guestAdult--;
            }

        },
        increaseChildGuest(state) {
            if (state.guestChild < 6) {
                state.guestChild++;
            }
        },
        decreaseChildGuest(state) {
            if (state.guestChild > 0) {
                state.guestChild--;
            }

        },
        resetBooking(state) {
            state = initialState;
        },
        setActiveStep(state, action) {
            state.activeStep = action.payload;
        },
        goToPreviousStep(state, action) {
            if (state.activeStep > action.payload)
                state.activeStep = action.payload;
        }
    }
})

export default BookingSlice.reducer;
export const BookingActions = BookingSlice.action;


