import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Link } from 'gatsby'
import { useDispatch, useSelector } from "react-redux";
import { BookingSlice } from "../../state/booking";


function HeaderBookBox() {

    // Use Booking Slice
    const dispatch = useDispatch();
    const [showGuest, setShowGuest] = useState(false);
    const ref = useRef();

    const checkinDate = useSelector(state => state.booking.checkin);
    const checkoutDate = useSelector(state => state.booking.checkout);
    const adultGuest = useSelector(state => state.booking.guestAdult);
    const childGuest = useSelector(state => state.booking.guestChild);

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    });

    function toggleGuestForm() {
        setShowGuest(true);
    }

    const handleClick = e => {
        if (ref.current && !ref.current.contains(e.target)) {
            setShowGuest(false);
        }
    };

    // DateFinder
    const handleCheckInDate = (date) => {
        dispatch(BookingSlice.actions.updateCheckin(date.getTime()));
        dispatch(BookingSlice.actions.updateCheckout(""));
    };
    const handleCheckOutDate = (date) => {
        dispatch(BookingSlice.actions.updateCheckout(date.getTime()));
    };




    function handleAdultIncreament() {
        dispatch(BookingSlice.actions.increaseAdultGuests());
    }

    function handleAdultDecreament() {
        dispatch(BookingSlice.actions.decreaseAdultGuests());
    }
    function handleChildIncreament() {
        dispatch(BookingSlice.actions.increaseChildGuest());
    }
    function handleChildDecreament() {
        dispatch(BookingSlice.actions.decreaseChildGuest());
    }
    return (
        <>
            <div className="text-center">
                <div className="rounded-pill" alt="">
                    <Row className="HeaderBox">
                        <Col md={3} sm={4} xs={12} className="Headercol">
                            <div className="HeaderBoxTitle">
                                <h4>Check in</h4>
                                <DatePicker
                                    className="HeaderBoxInputDate"
                                    selected={checkinDate}
                                    minDate={new Date()}
                                    onChange={handleCheckInDate}
                                    selectsStart
                                    dateFormat="dd/MM/yyyy"
                                    startDate={checkinDate}
                                    endDate={checkoutDate}
                                    placeholderText="Select Date"
                                />
                            </div>
                        </Col>
                        <Col md={3} sm={4} xs={12} className="Headercol">
                            <div className="HeaderboxVertical">
                                <div className="HeaderBoxTitle">
                                    <h4> Check out</h4>
                                    <DatePicker
                                        className="HeaderBoxInputDate"
                                        selected={checkoutDate}
                                        minDate={checkinDate}
                                        onChange={handleCheckOutDate}
                                        selectsEnd
                                        dateFormat="dd/MM/yyyy"
                                        startDate={checkinDate}
                                        endDate={checkoutDate}
                                        placeholderText="Select Date"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md={6} sm={12} xs={12} className="Headercol">
                            <div className="HeaderboxVertical">
                                <Row className="HeaderboxGuestRoom">
                                    <Col xl={6} lg={6} md={6} sm={6} xs={6} className="HeaderBoxTitle">
                                        <h4> Guests No. </h4>
                                        <div className="Room-bar">
                                            {/* Menu*/}
                                            <div ref={ref} className="Room-bar-item" >
                                                <a className="Room-bar-link" href="#/" onClick={toggleGuestForm}>
                                                    <i class='bx bx-male' ></i> - {adultGuest}, <i class='bx bx-female' ></i> - {childGuest}
                                                </a>
                                                <div className={"mega-menu " + showGuest}>

                                                    <div className="mega-menu-content">

                                                        <div className="RoomButton">
                                                            <h4 className="d-inline">Adult</h4>
                                                            <button onClick={handleAdultIncreament} className="d-inline">+</button>
                                                            <input
                                                                className="inputne"
                                                                value={adultGuest}
                                                            />
                                                            <button onClick={handleAdultDecreament} className="d-inline">-</button>
                                                        </div>


                                                        <div className="RoomButton">
                                                            <h4 className="d-inline">Child</h4>
                                                            <button onClick={handleChildIncreament} className="d-inline">+</button>
                                                            <input
                                                                className="inputne"
                                                                value={childGuest}

                                                            />
                                                            <button onClick={handleChildDecreament} className="d-inline">-</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xl={6} lg={6} md={6} sm={6} xs={6} className="SearchIcon2">
                                        <Link className="default-btn" to="/checkout">
                                            Book Now
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default HeaderBookBox;
