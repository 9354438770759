import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { BookingSlice } from "../../state/booking";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function DatesDetails() {

    const dispatch = useDispatch();

    const checkinDate = useSelector(state => state.booking.checkin);
    const checkoutDate = useSelector(state => state.booking.checkout);
    const adultGuest = useSelector(state => state.booking.guestAdult);
    const childGuest = useSelector(state => state.booking.guestChild);

    const handleCheckInDate = (date) => {
        dispatch(BookingSlice.actions.updateCheckin(date.getTime()));
        dispatch(BookingSlice.actions.updateCheckout(""));
    };
    const handleCheckOutDate = (date) => {
        dispatch(BookingSlice.actions.updateCheckout(date.getTime()));
    };

    const handleAdultGuestChange = (event) => {
        dispatch(BookingSlice.actions.setAdultGuest(event.target.value));
    }

    const handleChildGuestChange = (event) => {
        dispatch(BookingSlice.actions.setChildGuest(event.target.value));
    }

    const nextStep = (e) => {
        e.preventDefault();
        if (checkinDate > 0 ) {
            dispatch(BookingSlice.actions.setActiveStep(1));
        } else {
            alert("Please select dates and guests");
        }
    }
    return (
        <>
            <section className="contact-area pb-70">
                <div className="container contact-form">
                    <h5>Select Dates </h5>
                    <form id="checkoutDatesForm"  >

                        <div className="row ptb-30">
                            <div className="col-lg-6 col-md-12">
                                <div className="form-group ptb-10">
                                    <DatePicker
                                        className="form-control"
                                        selected={checkinDate}
                                        required
                                        minDate={new Date()}
                                        onChange={handleCheckInDate}
                                        selectsStart
                                        dateFormat="MMMM d, yyyy"
                                        startDate={checkinDate}
                                        endDate={checkoutDate}
                                        placeholderText="Select Checkin"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="form-group ptb-10">
                                    <DatePicker
                                        className="form-control"
                                        selected={checkoutDate}
                                        minDate={checkinDate}
                                        onChange={handleCheckOutDate}
                                        selectsEnd
                                        dateFormat="MMMM d, yyyy"
                                        startDate={checkinDate}
                                        endDate={checkoutDate}
                                        placeholderText="Select Checkout"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-lg-6 col-md-12 form-group ptb-10">
                                <FormControl fullWidth>
                                    <InputLabel id="adult-guest-label">Adult/s</InputLabel>
                                    <Select
                                        labelId="adult-guest-label"
                                        className="form-control"
                                        id="adult-guest"
                                        required
                                        value={adultGuest}
                                        label="Adults"
                                        onChange={handleAdultGuestChange}
                                    >
                                        <MenuItem value={0}> Select </MenuItem>
                                        <MenuItem value={1}> 1 </MenuItem>
                                        <MenuItem value={2}> 2 </MenuItem>
                                        <MenuItem value={3}> 3 </MenuItem>
                                        <MenuItem value={4}> 4 </MenuItem>
                                        <MenuItem value={5}> 5 </MenuItem>
                                        <MenuItem value={6}> 6 </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-lg-6 col-md-12 form-group ptb-10">

                                <FormControl fullWidth>
                                    <InputLabel id="child-guest-label">Childeren</InputLabel>
                                    <Select
                                        labelId="child-guest-label"
                                        id="child-guest-label"
                                        className="form-control"
                                        required
                                        value={childGuest}
                                        label="Childeren"
                                        onChange={handleChildGuestChange}
                                    >
                                        <MenuItem value={0}> Select </MenuItem>
                                        <MenuItem value={1}> 1 </MenuItem>
                                        <MenuItem value={2}> 2 </MenuItem>
                                        <MenuItem value={3}> 3 </MenuItem>
                                        <MenuItem value={4}> 4 </MenuItem>
                                        <MenuItem value={5}> 5 </MenuItem>
                                        <MenuItem value={6}> 6 </MenuItem>
                                    </Select>
                                </FormControl>

                            </div>
                        </div>
                        <div className="row ptb-10">

                            <div className="col-lg-6 col-md-12 ptb-10">
                                <button type="button" onClick={(e) => nextStep(e)} className="default-btn">
                                    Continue <span></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

            </section >
        </>
    )

}

export default DatesDetails;