import React from "react";

import { useSelector } from "react-redux";
import MiniCart from "./MiniCart";
import DatesDetails from "./DatesDetails-1";
import RoomDetails from "./RoomsDetails-2";
import GuestPersonalDetails from "./GuestPersonalDetails-4";

import CheckoutSteps from "./CheckoutSteps";


function CheckoutWidget() {

    const currentStep = useSelector(state => state.booking.activeStep);

    return (
        <>
            <div className="courses-details-area pb-100">
                <div className="courses-details-image">

                </div>
                <div className="container">

                    <div className="row">

                        <div className="col-lg-8 col-md-12">
                            <div className="ptb-30">
                                <CheckoutSteps />
                            </div>
                            {currentStep === 0 &&
                                <div className="courses-details-desc">
                                    <DatesDetails />
                                </div>}
                            {currentStep === 1 &&
                                <div className="courses-details-desc">
                                    <RoomDetails />
                                </div>}
                            {currentStep === 2 &&
                                <div className="courses-details-desc">
                                    <GuestPersonalDetails />
                                </div>}
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <MiniCart />
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default CheckoutWidget;
