import React from "react";

const ContactInfo = () => {
  return (
    <div className="contact-info-area pbt-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="contact-info-box">
              <div className="back-icon">
                <i className="bx bx-map"></i>
              </div>
              <div className="icon">
                <i className="bx bx-map"></i>
              </div>
              <h3>Registered Office</h3>
              Perisun Hospitality Private Limited,
              <br />
              Plot 424, Beri Road, <br />
              Dubaldhan, Jhajjar, <br />
              Haryana, India - 124202
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="contact-info-box">
              <div className="back-icon">
                <i className="bx bx-phone-call"></i>
              </div>
              <div className="icon">
                <i className="bx bx-phone-call"></i>
              </div>
              <h3>Contact</h3>
              <p>
                Mobile: <a href="tel:+91781709909">(+91) - 781709909</a>
              </p>
              <p>
                E-mail: <a href="mailto:hi@perisun.com">hi@perisun.com</a>
              </p>
              <p>
                WhatsApp:
                <a href="https://www.gupshup.io/whatsapp/optin?bId=b1b13b2b-dc68-4a9e-9468-63ee0d2ef4f0&bName=PerisunCS&s=URL&lang=en_US">
                  {" "}
                  +91 90341 18378
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
