import React, { useState } from 'react'

function GuestPersonalDetails() {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    return (
        <>
            <section className="contact-area pb-70">
                <div className="container contact-form">
                    <h5>Billing Details</h5>

                    <div className="row ptb-30">
                        <div className="col-lg-12 col-md-12">
                            <form id="checkoutDetailsForm"  >
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <input type="text" name="firstName" onChange={(e) => setFirstName(e.target.value)} value={firstName} className="form-control" required placeholder="Your first name" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <input type="text" name="lastName" onChange={(e) => setLastName(e.target.value)} value={lastName} className="form-control" required placeholder="Your last name" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} className="form-control" required placeholder="Your email address" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="form-group">
                                        <input type="text" name="phone" onChange={(e) => setPhone(e.target.value)} value={phone} className="form-control" required placeholder="Your phone number" />
                                    </div>
                                </div>



                                <div className="col-lg-6 col-md-12">
                                    <button type="submit" className="default-btn">
                                        Pay Now <span></span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

export default GuestPersonalDetails;